<template>
  <div class="data-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column label="圖片" prop="img" align="center">
        <template slot-scope="scope">
          <img style="margin: auto;" width="90" :src="scope.row.img" alt="">
        </template>
      </el-table-column>
      <el-table-column label="名稱" prop="name" align="center" />
      <el-table-column label="範本性質" width="120" prop="type" align="center" />
      <el-table-column label="權益項目" width="150" prop="entitlements" align="center" />
      <el-table-column label="發放週期" width="150" prop="period" align="center">
        <template slot-scope="{ row }">
          <div v-html="row.period" />
        </template>
      </el-table-column>
      <el-table-column label="發放總次數" prop="totalIssueCount" align="center" />
      <el-table-column label="其他發放條件" prop="conditions" align="center" />
      <el-table-column label="排序" prop="order" align="center" />
      <el-table-column label="操作" fixed="right" width="150" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            :hideCopy="false"
            editBtn="查看"
            @edit="onRowEdit(scope.row)"
            @delete="onRowDelete(scope.row)"
            @copy="onRowCopy(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="deleteRow"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive, onBeforeMount } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { DeletePeriodicBenefitTemplate } from '@/api/periodicBenefit'
import { useTable } from '@/use/table'
import { get, map, join, find } from 'lodash'
import { periodicBenefitTemplateTypeConfig } from '@/config/periodicBenefit'
import notifyMessage from '@/config/notifyMessage'
import { useRouter } from 'vue-router/composables'
import { usePeriodBenefitTemplate } from '@/use/usePeriodBenefit'
import { useMemberLevel } from '@/use/memberLevel'
import { imgSrc } from '@/utils/helper'

export default defineComponent({
  name: 'PeriodicBenefitTemplateTable',
  components: { EmptyBlock },
  props: {
    tableData: { type: Array, defalt: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const { shopId } = useTable()
    const router = useRouter()
    const { formatPeriodPreviewText } = usePeriodBenefitTemplate()
    const { getMemberLevelConfig, memberLevelConfig } = useMemberLevel()
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
    })

    const displayData = computed(() => {
      const data = props.tableData

      return map(data, (item) => {
        let fixedWeekDay
        const startType = get(item, 'period.startType', '-')
        const periodType = get(item, 'period.type', '-')
        const nDayValue = get(item, 'period.nDayValue', '-')
        const fixedValue = get(item, 'period.fixedValue', '-')
        if (startType === 'fixed' && periodType === 'weekly') {
          fixedWeekDay = fixedValue
        }

        const conditions = get(item, 'conditions', [])
        const levelCondition = find(conditions, { type: 'level' })
        const displayConditions = {
          level: '-',
        }
        if (levelCondition) {
          const levels = map(get(levelCondition, 'levels', []), (level) => {
            const levelName = get(find(memberLevelConfig.value, { level }), 'name', '-')
            return levelName
          })
          displayConditions.level = `${join(levels, '、')}`
        }

        return {
          ...item,
          id: item.id,
          img: imgSrc(300, item.Image),
          name: item.name,
          order: item.order,
          type: get(periodicBenefitTemplateTypeConfig, `${item.type}.label`, '-'),
          entitlements: join(map(get(item, 'entitlements', '-'), (item) => {
            const itemData = get(item, 'StandardAward', {})
            if (itemData.type === 'classTicket') {
              return `${itemData.name} ${itemData.amount} 份`
            } else if (itemData.type === 'coupon') {
              return `${itemData.name} ${itemData.amount} 張`
            } else if (itemData.type === 'pointCard') {
              return `${itemData.name} ${itemData.amount} 張`
            } else if (itemData.type === 'punchCard') {
              return `${itemData.name} ${itemData.amount} 份`
            } else if (itemData.type === 'wallet') {
              return `儲值金 ${itemData.amount} 元`
            } else if (itemData.type === 'cashback') {
              return `回饋金 ${itemData.amount} 元`
            } else if (itemData.type === 'point') {
              return `點數 ${itemData.amount} 點`
            }
          }), '、'),
          totalIssueCount: item.totalAwardCount,
          period: formatPeriodPreviewText({
            startType,
            periodType,
            fixedWeekDay,
            nDayValue,
            fixedValue,
            instantAward: item.instantAward,
            totalAwardCount: item.totalAwardCount,
          }),
          conditions: displayConditions.level,
        }
      })
    })

    const onRowEdit = (row) => {
      router.push({
        name: 'PreviewPeriodicBenefitTemplate',
        params: {
          shopId: shopId.value,
          id: row.id,
        },
      })
    }

    const onRowDelete = (row) => {
      selectRow.value = row
      modal.delete = true
    }

    const onRowCopy = (row) => {
      router.push({
        name: 'EditPeriodicBenefitTemplate',
        params: {
          shopId: shopId.value,
          id: row.id,
        },
        query: {
          isCopy: true,
        },
      })
    }

    const deleteRow = async () => {
      const [, err] = await DeletePeriodicBenefitTemplate({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success(notifyMessage.deleteSuccess)
      modal.delete = false
      emit('refresh')
    }

    onBeforeMount(() => {
      getMemberLevelConfig()
    })

    return {
      modal,
      displayData,
      onRowEdit,
      onRowDelete,
      deleteRow,
      onRowCopy,
    }
  },
})
</script>
